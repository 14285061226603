<template>
  <v-container fluid>
    <SharedToolbar enableSidebar />
    <v-card>
      <v-card-title>
        QRコード
      </v-card-title>
      <v-card-text>
        <div v-show="indeterminate" class="text-center"><v-progress-circular :indeterminate="indeterminate"/></div>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn text @click="downloadDialog=true">QRコードCSVのダウンロード</v-btn>
        <v-spacer/>
      </v-card-actions>
    </v-card>
    <QRCodeStatsDownloadDialog :model="downloadDialog" @downloadStats="onDownloadStats" @closeDownload="onCloseDownload()"/>
    <a v-show="false" ref="doDownload" :href="csvUrl" :download="csvFilename">click</a>

  </v-container>
</template>

<script>
import format from 'date-fns/format'
import flattenDeep from 'lodash.flattendeep'
import { firebase } from '@/services/firebase'
import { adminGetOwners, adminGetQRCodes, adminGetMixpanelEvents, adminGetResponse } from '@/services/functions'
import SharedToolbar from '../components/SharedToolbar'
import QRCodeStatsDownloadDialog from '../components/QRCodeStatsDownloadDialog'

const SCENARIO_ID = 'I50eomEXZFI5GxgC7kXk'

export default {
  components: {
    SharedToolbar,
    QRCodeStatsDownloadDialog
  },
  data: () => ({
    downloadDialog: false,
    csvUrl: '',
    csvFilename: '',
    indeterminate: false
  }),
  methods: {
    clean  (text) {
      return text ? text.replace(/\n/g, ' ') : text
    },
    shortenURLIfRequired (long) {
      let short = long
      const MAX_LENGTH = 21
      if (long.length > MAX_LENGTH) {
        short = long.substr(0, MAX_LENGTH) + '...'
      }
      return short
    },
    getQRCodeCounts ({ ownerId, events }) {
      const scans = events.filter(event => {
        const { event: eventName, properties: { ownerId: eventOwnerId } } = event
        return eventName === 'Onboarded from campaign' && eventOwnerId === ownerId
      })
      const scannedCounts = {}
      for (const scan of scans) {
        const { properties: { campaignId } } = scan
        scannedCounts[campaignId] = scannedCounts[campaignId] ? scannedCounts[campaignId] + 1 : 1
      }

      const interviews = events.filter(event => {
        const { event: eventName, properties: { ownerId: eventOwnerId } } = event
        return eventName === 'Completed Scenario' && eventOwnerId === ownerId
      })

      const interviewCounts = {}
      for (const interview of interviews) {
        const { properties: { campaignId } } = interview
        interviewCounts[campaignId] = interviewCounts[campaignId] ? interviewCounts[campaignId] + 1 : 1
      }

      return { scannedCounts, interviewCounts }
    },
    async getQRCodesWithCounts (ownerId, scenarioId, events) {
      const { data: qrCodes } = await adminGetQRCodes({ ownerId, scenarioId })
      const { scannedCounts, interviewCounts } = this.getQRCodeCounts({ ownerId, events })
      const qrCodesWithCounts = qrCodes.map(code => {
        const { qrCodeId, url: long } = code
        const url = long ? this.shortenURLIfRequired(long) : ''
        return {
          ...code,
          url,
          count: scannedCounts[qrCodeId] ? scannedCounts[qrCodeId] : 0,
          interviews: interviewCounts[qrCodeId] ? interviewCounts[qrCodeId] : 0
        }
      })
      return qrCodesWithCounts
    },
    onCloseDownload () {
      this.downloadDialog = false
    },
    async getResponses (ownerId, events) {
      const interviews = events.filter(event => {
        const { event: eventName, properties: { ownerId: eventOwnerId } } = event
        return eventName === 'Completed Scenario' && eventOwnerId === ownerId
      })
      // console.log({ interviews })

      const responses = await Promise.all(
        interviews.map(async interview => {
          const { uid: applicantId, campaignId: qrCodeId, campaignType: qrCodeType, $current_url: url } = interview.properties
          const { data: response } = await adminGetResponse({ ownerId, applicantId })
          return { ...response, qrCodeId, qrCodeType, url }
        })
      )
      return responses
    },
    async onDownloadStats ({ startDate, endDate }) {
      this.downloadDialog = false
      try {
        this.indeterminate = true
        const { data: owners } = await adminGetOwners()
        const { data: events } = await adminGetMixpanelEvents({ startDate, endDate, eventNames: ['Completed Scenario', 'Onboarded from campaign'] })

        let allQRCodesWithCounts = []
        for (const owner of owners) {
          let qrCodesWithCounts = await this.getQRCodesWithCounts(owner.id, SCENARIO_ID, events)
          qrCodesWithCounts = qrCodesWithCounts.map(code => {
            return { ...code, ...owner }
          })
          allQRCodesWithCounts.push(qrCodesWithCounts)
        }
        allQRCodesWithCounts = flattenDeep(allQRCodesWithCounts)

        const title = '"Internal ID", 会社名, メールアドレス, ラベル , URL, ID, 募集者数, スキャン回数'
        const csvLines = allQRCodesWithCounts.map(code => {
          let { id, companyNameJa, managerEmail, label, url, qrCodeId, interviews, count } = code
          companyNameJa = companyNameJa || ''
          managerEmail = managerEmail || ''
          return `${id}, ${companyNameJa}, ${managerEmail}, ${label}, ${url}, ${qrCodeId}, ${interviews}, ${count}`
        })
        let csvContent = [title].concat(csvLines)
        csvContent = csvContent.join('\n')

        let blob = new Blob([csvContent], { type: 'text/csv' })
        this.csvUrl = URL.createObjectURL(blob)
        this.csvFilename = `QRCODE_STATS_${startDate.replace(/-/g, '')}-${endDate.replace(/-/g, '')}.csv`
        this.$nextTick(() => {
          this.$refs.doDownload.click()
        })

        console.log({ events })

        const e = events.filter(event => {
          return event.event === 'Completed Scenario' && event.properties.applicantName.indexOf('です') !== -1
        })

        console.log({ e })

        let allCSVLines = []
        for (const owner of owners) {
          const { companyNameJa, companyNameEn, managerEmail, id: ownerId } = owner
          const companyName = companyNameJa || companyNameEn
          const responses = await this.getResponses(ownerId, events)
          const { data: qrCodeInfos } = await adminGetQRCodes({ ownerId, scenarioId: SCENARIO_ID })
          const csvLines = responses.map(response => {
            let { applicantName, createdAt, qrCodeId, qrCodeType, url, optInEmail } = response
            applicantName = this.clean(applicantName)
            const timestamp = new firebase.firestore.Timestamp(createdAt._seconds, createdAt._nanoseconds)
            createdAt = format(timestamp.toDate(), 'yyyy/MM/dd HH:mm:ss')
            const found = qrCodeInfos.find(info => info.qrCodeId === qrCodeId, qrCodeId)
            const label = found ? found.label : ''
            qrCodeType = found ? found.qrCodeType : qrCodeType
            url = found ? found.url : url
            const fan = optInEmail && optInEmail === 'OK' ? 'はい' : 'いいえ'
            const csvLine = `${ownerId}, ${companyName}, ${managerEmail}, ${applicantName}, ${createdAt}, ${label}, ${qrCodeType}, ${url}, ${qrCodeId}, ${fan}`
            return csvLine
          })
          allCSVLines.push(csvLines)
        }
        allCSVLines = flattenDeep(allCSVLines)
        allCSVLines = ['Internal Id, Company, メールアドレス, 応募者氏名, 応募日時, QRコードラベル, QRコードタイプ, QRコードURL, QRコードID, ファン'].concat(allCSVLines)
        allCSVLines = allCSVLines.join('\n')

        blob = new Blob([allCSVLines], { type: 'text/csv' })
        this.csvUrl = URL.createObjectURL(blob)
        this.csvFilename = `QRCODE_DETAILS_${startDate.replace(/-/g, '')}-${endDate.replace(/-/g, '')}.csv`
        this.$nextTick(() => {
          this.$refs.doDownload.click()
        })
      } catch (err) {
        alert('QRコード統計情報をダウンロードできませんでした。再度試してください。')
        console.log({ err })
      } finally {
        this.indeterminate = false
      }
    }
  }
}
</script>
